// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "./add-jquery"
import * as bootstrap from "bootstrap"
require('datatables.net');
require('datatables.net-bs5');


import Chart from 'chart.js/auto';
import 'chart.js/helpers';
window.Chart = Chart;

import Swal from "sweetalert2";
window.Swal = Swal;

import DataTables from "datatables.net-bs5";
window.DataTables = new DataTables();

import WOW from "wow.js"
wow = new WOW();
window.wow.init();

