import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';
import Select2 from "select2"

// Connects to data-controller="select2"
export default class extends Controller {
  connect() {
    Select2();
    console.log("Connecting to data-controller");
    $('.content-search').select2({
      theme: 'bootstrap-5'
    });

  }
}
